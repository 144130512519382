import "./App.css";
import BlogPage from "./components/blog/BlogPage";
import SingleBlogPage from "./components/blog/SingleBlogPage";
import LandingPage from "./components/landingPage/LandingPage";
import DemoPage from "./components/DemoPage";
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";
import WebsiteSection from "./components/WebsiteSection";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FeaturesSection from "./components/FeaturesSection";
import ImageSlider from "./components/UsageSection";
import PriceSection from "./components/priceSection/PriceSection";
import PartnershipSection from "./components/PartnershipSection";
import BookingSystemSection from "./components/BookingSystemSection";
import EmbeddedSection from "./components/EmbeddedSection";
import EbookPage from "./components/EbookPage";

function App() {
  return (
    <HelmetProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/funkciok" element={<FeaturesSection />} />
        <Route
          path="/online-idopontfoglalo"
          element={<BookingSystemSection />}
        />
        <Route path="/weboldal" element={<WebsiteSection />} />
        <Route path="/csomagok" element={<PriceSection />} />
        <Route path="/kiknek" element={<ImageSlider />} />
        <Route path="/partner" element={<PartnershipSection />} />
        <Route path="/beagyazas" element={<EmbeddedSection />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:title" element={<SingleBlogPage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/ebook" element={<EbookPage />} />
        <Route path="*" element={<div>Oldal nem található</div>} />
      </Routes>
      <Footer />
    </HelmetProvider>
  );
}

export default App;

import React from "react";
import { Box, Image, useBreakpointValue } from "@chakra-ui/react";

type MonitorSectionProps = {
  imageUrl: string;
};

const MonitorSection: React.FC<MonitorSectionProps> = ({ imageUrl }) => {
  const monitorWidth = useBreakpointValue({
    base: "100%",
    md: "calc(100% + 50px)",
  }) as string;

  return (
    <Box
      position="relative"
      width={monitorWidth}
      overflow="hidden"
      minWidth="350px"
      maxWidth="800px"
      aspectRatio="3 / 2"
      border="10px solid #333"
      borderRadius="10px"
      boxShadow="rgba(0, 0, 0, 0.4) -11px 4px 8px"
      bg="black"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box overflowY="scroll" height="100%" width="100%">
        <Image src={imageUrl} alt="wobble weboldal" width="100%" />
      </Box>
    </Box>
  );
};

export default MonitorSection;

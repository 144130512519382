import { Box } from "@chakra-ui/react";
import MainSection from "./MainSection";
import CalendarPartSection from "../CalendarPartSection";
import DescriptionSection from "./DescriptionSection";
import Contacts from "./Contacts";
import AboutUsSection from "./AboutUsSection";
import FAQSection from "./FAQSection";
import Subscribe from "./Subscribe";
import SEO from "../../SEO";
import AdModal from "../AdModal";

export const mainColor = "#007188";
export const darkColor = "#024856";
export const lightColor = "#5AB3C5";
export const greyColor = "#E9F0F1";

const LandingPage = () => {
  return (
    <Box bg="gray.50" color="gray.800">
      <SEO
        title="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        description="Egyszerű és gyors online időpontfoglaló rendszer és weboldal egyéni- és kisvállalkozásoknak."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer, online megjelenés"
        ogDescription="Foglalási rendszer kisvállalkozásoknak. Próbáld ki ingyen!"
      />

      <AdModal />

      <MainSection />

      <CalendarPartSection />

      <DescriptionSection />

      <AboutUsSection />

      <FAQSection />

      <Subscribe />

      <Contacts />
    </Box>
  );
};

export default LandingPage;

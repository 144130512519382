import { Box, Text, AspectRatio } from "@chakra-ui/react";
import { darkColor } from "./LandingPage";

const Subscribe = () => {
  return (
    <Box
      id="subscribe"
      mt={30}
      mb={20}
      bg="gray.50"
      borderRadius="md"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      px={4}
    >
      <Text
        as="h1"
        fontSize={{ base: "xl", md: "3xl" }}
        fontWeight="bold"
        maxW={1200}
        color={darkColor}
        sx={{ WebkitTextSizeAdjust: '100%' }}
      >
        Szeretnél elsőként értesülni a legújabb funkcióinkról? Iratkozz fel
        hírlevelünkre, így garantáltan nem maradsz le sem az aktuális
        ajánlatainkról, sem a legfrissebb fejlesztéseinkről, amelyekkel
        vállalkozásodat támogatjuk.
      </Text>
      <AspectRatio ratio={16 / 9} w="100%" h="500px">
        <iframe
          title="Responsive Iframe"
          src="https://app.wobble.hu/subscribe"
        />
      </AspectRatio>
    </Box>
  );
};

export default Subscribe;


import { FC, ReactNode, useState } from "react";

import { Box, Flex, Stack, Text, Switch } from "@chakra-ui/react";
import { mainColor } from "../landingPage/LandingPage";
import PriceCard from "./PriceCard";
import SEO from "../../SEO";

const PriceSection: FC = () => {
  const [isAnnual, setIsAnnual] = useState<boolean>(false);

  const togglePricing = () => setIsAnnual((prev) => !prev);

  const proFeaturesList: string[] = [
    "Személyre szabott időpontfoglalási oldal",
    "Korlátlan szolgáltatás létrehozása",
    "Korlátlan időpont létrehozása",
    "Email értesítő a foglalásokról",
    "Emlékeztető email",
    "Google Naptár szinkronizáció",
    "Egyedi Google Meet link generálás",
    "Ügyfélkezelés",
    "Értékelések ügyfelektől",
    "Vállalkozás/szolgáltatások bemutatása",
    "Email küldés",
    "Egyedi kérdőív készítés",
    "Egyedi URL link a foglalási oldalhoz",
  ];

  const webProFeaturesList: string[] = [
    "Egyedi weboldal időpontfoglalási rendszerrel",
    "Saját Domain használat",
    "Ingyenes SSL tanúsítvány",
    "Blogolási lehetőség és publikálás a weboldaladon",
  ];

  const proBonusList: string[] = [
    "1 alkalom 30 perc személyre szabott konzultáció: Megoldjuk, ahol elakadtál!",
  ];

  const webProBonusList: string[] = [
    "1 alkalom 30 perc személyre szabott konzultáció: Megoldjuk, ahol elakadtál!",
    "1 alkalom 60 perc profi weboldal-optimalizálás: Útmutatás az oldal beállításokban.",
  ];

  const proMonthlyPrice: ReactNode = "2 999 Ft";
  const proAnnualPrice: ReactNode = (
    <Box>
      <Text as="del" fontSize="xl" color="brand.2">
        35 988 Ft
      </Text>
      <Text>helyett 29 999 Ft</Text>
    </Box>
  );

  const webProMonthlyPrice: ReactNode = "5 899 Ft";
  const webProAnnualPrice: ReactNode = (
    <Box>
      <Text as="del" fontSize="xl" color="brand.2">
        70 788 Ft
      </Text>
      <Text>helyett 58 990 Ft</Text>
    </Box>
  );

  return (
    <>
      <SEO
        title="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        description="Egyszerű és gyors online időpontfoglaló rendszer és weboldal egyéni- és kisvállalkozásoknak."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-szepsegiparban.png"
      />
      <Box id="pricing" p={{ base: 4, md: 8 }} bg="gray.50">
        <Flex direction="column" align="center">
          <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
            Árak
          </Text>
          <Text fontSize="2xl" fontWeight="bold" color={mainColor} mb={4}>
            Próbáld ki 30 napig ingyen!
          </Text>

          <Flex align="center" mb={6}>
            <Text>Havi</Text>
            <Switch
              colorScheme="teal"
              isChecked={isAnnual}
              onChange={togglePricing}
              mx={2}
            />
            <Text>Éves</Text>
          </Flex>

          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="flex-start"
            justify="center"
            maxW="7xl"
            mx="auto"
            mb={30}
          >
            <PriceCard
              packageName="Pro Csomag"
              monthlyPrice={proMonthlyPrice}
              annualPrice={proAnnualPrice}
              features={proFeaturesList}
              isAnnual={isAnnual}
              buttonLink="https://app.wobble.hu/signup_page"
              bonusList={proBonusList}
            />
            <PriceCard
              packageName="WebPro Csomag"
              monthlyPrice={webProMonthlyPrice}
              annualPrice={webProAnnualPrice}
              features={webProFeaturesList}
              isAnnual={isAnnual}
              buttonLink="https://app.wobble.hu/signup_page"
              bonusList={webProBonusList}
            />
          </Stack>
        </Flex>
      </Box>
    </>
  );
};

export default PriceSection;

import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { FaLightbulb, FaClock, FaUserFriends } from "react-icons/fa";

const AboutUsSectionAlt = () => {
  return (
    <Box
      id="aboutus"
      as="section"
      bgGradient="linear(to-l, gray.50, gray.300)"
      py={{ base: 10, md: 20 }}
      px={{ base: 6, md: 12 }}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        direction="column"
        align="center"
        textAlign="center"
        mb={12}
        mx="auto"
        maxW="100%"
      >
        <Heading as="h1" size="xl" mb={4} color="teal.600" fontWeight="bold">
          Kik vagyunk mi?
        </Heading>
        <Text fontSize="lg" color="gray.700" maxW="2xl">
          Több éves fejlesztői és DevOps tapasztalattal a hátunk mögött
          elhivatottan dolgozunk azon, hogy a <strong>Wobble</strong>{" "}
          segítségével minden kezdő és kisvállalkozó, aki ügyfelekkel dolgozik,
          és akinek fontos a folyamatos, könnyű elérhetőség, ne kerüljön
          hátrányba az erőforrások vagy az informatikai ismeretek hiánya miatt.
          Rendszerünk segíti az elindulást és a növekedést, hogy különösen a
          kezdeti időszakban ne legyen akadály a megfelelő online megjelenés és
          a digitális rendszerek hiánya.
        </Text>
        <Text fontSize="lg" color="gray.700" maxW="2xl">
          Drága egy weboldal? Nem tudod, hogyan kezdj bele az email küldésbe?
          Gondot okoz az ügyfelek nyilvántartása vagy az ügyfélkezelés? Blogot
          indítanál, hogy közvetlenebb kapcsolatot alakíts ki velük? Mi
          mindebben segítünk! Keress minket bizalommal, és találjuk meg együtt a
          megoldást!
        </Text>
      </Flex>

      {/* Icon Highlights */}
      <Flex
        justify="center"
        gap={10}
        wrap={{ base: "wrap", md: "nowrap" }}
        align="center"
      >
        <VStack
          bg="gray.50"
          p={6}
          rounded="md"
          boxShadow="md"
          maxW="sm"
          textAlign="center"
        >
          <Icon
            as={FaLightbulb}
            boxSize={12}
            color="teal.500"
            aria-label="Egyszerű megoldás"
          />
          <Heading as="h3" size="md" color="teal.600">
            Egyszerű megoldás
          </Heading>
          <Text fontSize="md" color="gray.700">
            Mi a <strong>Wobble</strong>-nél egyszerű, átlátható és
            felhasználóbarát megoldásokat kínálunk, hogy a vállalkozásod minden
            szükséges eszköze egy helyen elérhető legyen.
          </Text>
        </VStack>

        <VStack
          bg="gray.50"
          p={6}
          rounded="md"
          boxShadow="md"
          maxW="sm"
          textAlign="center"
        >
          <Icon
            as={FaClock}
            boxSize={12}
            color="teal.500"
            aria-label="Spórolj időt"
          />
          <Heading as="h3" size="md" color="teal.600">
            Spórolj időt
          </Heading>
          <Text fontSize="md" color="gray.700">
            Rendszerünk segít neked időt spórolni, hiszen online felületünkön
            mindent könnyedén kezelhetsz - így az adminisztráció helyett inkább
            az ügyfeleidre fókuszálhatsz.
          </Text>
        </VStack>

        <VStack
          bg="gray.50"
          p={6}
          rounded="md"
          boxShadow="md"
          maxW="sm"
          textAlign="center"
        >
          <Icon
            as={FaUserFriends}
            boxSize={12}
            color="teal.500"
            aria-label="Ügyfélközpontúság"
          />
          <Heading as="h3" size="md" color="teal.600">
            Ügyfélközpontúság
          </Heading>
          <Text fontSize="md" color="gray.700">
            Mi az egyszerűséget és a hatékony kommunikációt tartjuk szem előtt:
            könnyen kezelhető foglalás, ügyfélkezelés és email-es elérés
            biztosítja, hogy minden kapcsolat gördülékenyen működjön.
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default AboutUsSectionAlt;

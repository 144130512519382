import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  Button,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { mainColor } from "./LandingPage";
import booking from "../../assets/images/mockup_gym.png"
import embedded from "../../assets/blog_images/embedded/wix_page_booking.png"
import website from "../../assets/images/lumiere-szalon-classic_web.png";

const MotionBox = motion(Box);
const MotionVStack = motion(VStack);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const features = [
  {
    image: website,
    title: "Professzionális weboldal, egyszerűen, időpontfoglalóval",
    description:
      "Modern, mobilbarát weboldal, ami bizalmat épít és növeli a bevételeidet — technikai tudás nélkül.",
    buttonLabel: "Megnézem",
    link: "/weboldal",
  },
  {
    image: booking,
    title: "Online időpontfoglaló rendszer",
    description:
      "Ügyfeleid bármikor foglalhatnak — te pedig időt spórolsz, csökkented a lemondásokat és átláthatóbbá teszed a napjaidat.",
    buttonLabel: "Megnézem",
    link: "/online-idopontfoglalo",
  },
  {
    image: embedded,
    title: "Időpontfoglalás a meglévő weboldaladon",
    description:
      "Már van weboldalad? Egyszerűen beágyazható foglalási rendszer, gyors beüzemelés, teljes funkcionalitás.",
    buttonLabel: "Megnézem",
    link: "/beagyazas",
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.2,
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
};

const TealFeatureSection: React.FC = () => {
  const gridColumns = useBreakpointValue({ base: 1, md: 3 });

  return (
    <Box px={{ base: 4, md: 16 }} py={12}>
      <MotionVStack
        spacing={4}
        textAlign="center"
        mb={12}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <MotionHeading size="2xl" color={mainColor} variants={itemVariants}>
          Egyszerű megoldások a profi online jelenlétért
        </MotionHeading>
        <MotionText
          fontSize="lg"
          color={mainColor}
          maxW="2xl"
          variants={itemVariants}
        >
          Modern weboldal és időpontfoglaló rendszer egy helyen - a Wobble
          segít, hogy több ügyfeled legyen és kevesebb időt tölts papírmunkával.
        </MotionText>
      </MotionVStack>

      <SimpleGrid columns={gridColumns} spacing={8}>
        {features.map((feature, index) => (
          <MotionBox
            key={index}
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            bg="white"
            boxShadow="lg"
            borderRadius="2xl"
            overflow="hidden"
          >
            <Image
              src={feature.image}
              alt={feature.title}
              objectFit="cover"
              w="100%"
              h="300px"
            />
            <Box p={6}>
              <Heading size="md" mb={2} color={mainColor}>
                {feature.title}
              </Heading>
              <Text color={mainColor} mb={4}>
                {feature.description}
              </Text>
              <Link href={feature.link} style={{ textDecoration: "none" }}>
                <Button
                  colorScheme="teal"
                  rightIcon={<ArrowForwardIcon />}
                  variant="solid"
                >
                  {feature.buttonLabel}
                </Button>
              </Link>
            </Box>
          </MotionBox>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default TealFeatureSection;

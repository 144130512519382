import React from "react";
import { Box, Heading, Icon, Text } from "@chakra-ui/react";
import { mainColor } from "../landingPage/LandingPage";


interface ListSectionProps {
  title: string;
  items: string[];
  icon: React.ElementType;
  iconColor?: string;
  iconWidth?: number | string;
  iconHeight?: number | string;
}

const BonusList: React.FC<ListSectionProps> = ({
  title,
  items,
  icon,
  iconColor = mainColor,
  iconWidth = 10,
  iconHeight = 5,
}) => {
  return (
    <Box>
      <Heading size="md" mt={4} mb={2}>
        {title}
      </Heading>
      {items.map((item, index) => (
        <Box display="flex" alignItems="center" mb={2} key={index}>
          <Icon as={icon} w={iconWidth} h={iconHeight} color={iconColor} />
          <Text ml={2}>{item}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default BonusList;

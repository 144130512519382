import { FC, useState } from "react";
import {
  Box,
  Text,
  Image,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

interface SectionProps {
  reverse: boolean;
  imageSrc: string;
  text: string;
  bgColor?: string;
  imageAlt?: string;
}

const Section: FC<SectionProps> = ({ imageSrc, text, bgColor, imageAlt }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    onOpen();
  };

  return (
    <>
      <Box py={2}>
        <Container maxW="container.xl">
          <Box>
            <Image
              src={imageSrc}
              alt={imageAlt}
              width="100%"
              height="auto"
              objectFit="contain"
              borderRadius="md"
              mx="auto"
              _hover={{ cursor: "pointer", transform: "scale(1.05)" }}
              onClick={() => handleImageClick(imageSrc)}
              transition="transform 0.3s"
            />
          </Box>
          <Box>
            <Text
              mt={4}
              fontSize={{ base: "lg", md: "xl" }}
              fontWeight="semibold"
            >
              {text}
            </Text>
          </Box>
        </Container>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="90vw"
          maxH="90vh"
          bg="transparent"
          boxShadow="none"
          position="relative"
        >
          <ModalCloseButton
            position="absolute"
            top="10px"
            right="10px"
            size="lg"
            color="white"
            bg="blackAlpha.700"
            _hover={{ bg: "blackAlpha.900" }}
          />
          <ModalBody
            p={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={selectedImage || ""}
              maxH="90vh"
              maxW="90vw"
              objectFit="contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Section;

import {
  Box,
  Flex,
  Button,
  VStack,
  HStack,
  IconButton,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { darkColor, lightColor, mainColor } from "./landingPage/LandingPage";
import logo_white from "../assets/logo/logo_white.png";
import logo from "../assets/logo/logo_wobble.png";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        as="header"
        bg={mainColor}
        color="white"
        py={4}
        px={8}
        justify="space-between"
        align="center"
        position="sticky"
        top="0"
        zIndex="1000"
        // w="100%"
      >
        <Box h={34} w={140} objectFit="cover">
          <Link to="/">
            <Image src={logo_white} alt="logo" />
          </Link>
        </Box>
        <HStack
          spacing={{ base: 1, md: 2 }}
          display={{ base: "none", lg: "flex" }}
        >
          <ChakraLink
            href="/"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Főoldal
          </ChakraLink>
          <ChakraLink
            href="/funkciok"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Funkciók
          </ChakraLink>
          <ChakraLink
            href="/online-idopontfoglalo"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Időpontfoglaló
          </ChakraLink>
          <ChakraLink
            href="/weboldal"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Weboldal
          </ChakraLink>
          <ChakraLink
            href="/beagyazas"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Beágyazás
          </ChakraLink>
          <ChakraLink
            href="/kiknek"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Kiknek?
          </ChakraLink>
          <ChakraLink
            href="/csomagok"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Árak
          </ChakraLink>
          <ChakraLink
            href="/partner"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Partnerség
          </ChakraLink>
          <ChakraLink
            as={Link}
            p={1}
            rounded={5}
            to="/demo"
            bg={darkColor}
            _hover={{ textDecoration: "none", bg: lightColor }}
          >
            Demo oldal
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="/blog"
            p={1}
            mr={2}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
            target="_blank"
          >
            Blog
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="https://help.wobble.hu/"
            p={1}
            mr={2}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
            target="_blank"
          >
            Súgó
          </ChakraLink>
        </HStack>
        <HStack spacing={4} display={{ base: "none", lg: "flex" }}>
          <Button
            colorScheme="white"
            variant="link"
            as="a"
            href="https://app.wobble.hu/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Belépés
          </Button>
          <Button
            colorScheme="white"
            variant="outline"
            as="a"
            href="https://app.wobble.hu/signup_page"
            target="_blank"
            rel="noopener noreferrer"
            _hover={{
              backgroundColor: darkColor,
              textDecoration: "none",
            }}
          >
            Regisztráció
          </Button>
        </HStack>
        <IconButton
          aria-label="Open Menu"
          icon={<FaBars />}
          display={{ base: "flex", lg: "none" }}
          onClick={onOpen}
          colorScheme="white"
        />
      </Flex>
      {/* Mobile Drawer */}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton color="teal" />
            <DrawerHeader>
              <Box h={34} w={140} objectFit="cover">
                <Image src={logo} alt="logo" />
              </Box>
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/"
                  onClick={onClose}
                >
                  Főoldal
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/funkciok"
                  onClick={onClose}
                >
                  Funkciók
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/online-idopontfoglalo"
                  onClick={onClose}
                >
                  Időpontfoglaló
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/weboldal"
                  onClick={onClose}
                >
                  Weboldal
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/beagyazas"
                  onClick={onClose}
                >
                  Beágyazás
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/kiknek"
                  onClick={onClose}
                >
                  Kiknek?
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/csomagok"
                  onClick={onClose}
                >
                  Árak
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="/partner"
                  onClick={onClose}
                >
                  Partnerség
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  to="/demo"
                  fontWeight="bold"
                  color={mainColor}
                  onClick={onClose}
                >
                  Demo oldal
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  to="/blog"
                  fontWeight="bold"
                  color={mainColor}
                  onClick={onClose}
                  target="_blank"
                >
                  Blog
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  to="https://help.wobble.hu/"
                  fontWeight="bold"
                  color={mainColor}
                  onClick={onClose}
                  target="_blank"
                >
                  Súgó
                </ChakraLink>
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="center">
              <Button
                as="a"
                href="https://app.wobble.hu/login"
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="teal"
                width="200px"
                // variant="outline"
                onClick={onClose}
                m={8}
              >
                Belépés
              </Button>
              <Button
                as="a"
                href="https://app.wobble.hu/signup_page"
                target="_blank"
                rel="noopener noreferrer"
                width="200px"
                colorScheme="teal"
                // variant="outline"
                onClick={onClose}
                m={8}
              >
                Regisztráció
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default Navbar;

import {
  Box,
  Flex,
  Text,
  Center,
  List,
  ListIcon,
  ListItem,
  Highlight,
  Heading,
  SimpleGrid,
  VStack,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { FaCheckCircle, FaShieldAlt, FaChartLine } from "react-icons/fa";
import { mainColor } from "./LandingPage";
import { SettingsIcon, CalendarIcon } from "@chakra-ui/icons";

const features = [
  {
    icon: [CalendarIcon],
    title: " Automatizált időpontfoglalás és értesítések",
    description: [
      "Korlátlan szolgáltatás létrehozása",
      "Korlátlan időpont létrehozása",
      "Email értesítő a foglalásokról",
      "Emlékeztető email",
      "Szolgáltatás értékelése email",
      "Google Calendar kapcsolat",
      "Egyedi Google Meet link",
    ],
  },
  {
    icon: [SettingsIcon],
    title: "Személyre szabható megoldások",
    description: [
      "Újdonság! Saját weboldal",
      "Személyre szabható foglalási felület",
      "Saját domain használat weboldaladhoz",
      "Egyedi URL link a foglalási oldalhoz",
      "Vállalkozás/szolgáltatások bemutatása",
    ],
  },
  {
    icon: [FaShieldAlt, FaChartLine],
    title: "Marketing és biztonság",
    description: [
      "Blog poszt készítés",
      "Időzített vagy azonnali email küldési lehetőség",
      "Referencia képek megjelenítése",
      "Ügyfél értékelések",
      "Ingyenes SSL tanúsítvány",
    ],
  },
];

const DescriptionSection = () => {
  return (
    <Box
      id="features"
      p={8}
      bg="gray.100"
      bgGradient="linear(to-t, gray.50, gray.300)"
    >
      <Center mt={50}>
        <Flex direction="column" align="center" maxW="1400px">
          <Box
            position="relative"
            backgroundSize="cover"
            backgroundPosition="center"
            zIndex={2}
          >
            <Center>
              <Heading color="#024856" m={4} textAlign="center" as="h1">
                Kapcsold vállalkozásodat autopilóta üzemmódba, és élvezd a
                szabadságot a fejlett foglalási funkciókkal!
              </Heading>
            </Center>
            <Box p={{ base: 4, md: 18, lg: 28 }} textAlign="center">
              <Text
                mt={4}
                align="center"
                pb={8}
                fontSize={{ base: "14px", md: "14px", lg: "28px" }}
                as="h2"
                fontWeight="normal"
              >
                Ha most indítottad el vállalkozásodat, vagy már működteted, de
                még nincs online megjelenésed a közösségi médián kívül, a Wobble
                segíthet abban, hogy egy egyszerű és átlátható online jelenlétet
                építs fel. Rendszerünk időpontfoglalóval, ügyfélkezeléssel és
                egyéb digitális megoldásokkal támogatja a vállalkozásod napi
                működését.
              </Text>
              <Text
                fontSize={{ base: "14px", md: "14px", lg: "28px" }}
                as="h2"
                fontWeight="normal"
              >
                Így nem kell többé aggódnod a papíralapú adminisztráció vagy a
                lemondások miatt, miközben kényelmesen kezelheted az
                ügyfeleidet. A Wobble segít, hogy könnyedén összekapcsold a
                szolgáltatásaidat az ügyfelekkel, így több időd marad a
                vállalkozásod fejlesztésére.
              </Text>
              <Text fontSize={{ base: "14px", md: "14px", lg: "28px" }} as="h2">
                <Highlight
                  query={"Próbáld ki most 30 napig teljesen ingyen"}
                  styles={{ px: "2", py: "1", rounded: "full", bg: "teal.100" }}
                >
                  Próbáld ki most 30 napig teljesen ingyen, és tapasztald meg,
                  milyen érzés, amikor az irányítás végre a te kezedben van!
                </Highlight>
              </Text>{" "}
            </Box>
          </Box>
        </Flex>
      </Center>
      <Center>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={8}
          mt={8}
          justifyContent="center"
          px={{ base: 4, md: 0 }}
        >
          {features.map((feature, index) => (
            <VStack
              key={index}
              p={5}
              border="1px"
              borderRadius="md"
              borderColor={mainColor}
              bg="white"
              align="center"
              textAlign="center"
            >
              <HStack spacing={4}>
                {" "}
                {feature.icon.map((icon, index) => (
                  <Icon key={index} as={icon} w={8} h={8} color={mainColor} />
                ))}
              </HStack>
              <Text fontSize="xl" fontWeight="bold" color={mainColor}>
                {feature.title}
              </Text>
              {feature.description.map((item, index) => {
                return (
                  <List spacing={3} textAlign="left" width="100%" key={index}>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      {item}
                    </ListItem>
                  </List>
                );
              })}
            </VStack>
          ))}
        </SimpleGrid>
      </Center>
    </Box>
  );
};

export default DescriptionSection;

import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import mockup_timeslot from "../assets/images/mockup_timeslot.png";
import mockup_blog from "../assets/images/mockup_blog.png";
import mockup_domain from "../assets/images/mockup_cutsom_domain.png";
import mockup_dashboard from "../assets/images/mockup_dashboard.png";
import mockup_email from "../assets/images/mockup_email.png";
import mockup_survey from "../assets/images/mockup_customer_survey.png";
import website_dog from "../assets/images/weboldal-kutyakozmetika.png";
import SwipeableImage from "./landingPage/SwipeableImage";
import MonitorSectionFuncion from "./MonitorSection2";
import SEO from "../SEO";

const FeatureSection = () => {
  return (
    <>
      <SEO
        title="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        description="Egyszerű és gyors online időpontfoglaló rendszer és weboldal egyéni- és kisvállalkozásoknak."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-edzoknek.png"
      />
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg="gray.50"
        color="white"
        textAlign="center"
        p={{ base: 8, md: 8, lg: 12 }}
      >
        <Flex direction="column" align="center" mt={{ base: 12, sm: 0 }}>
          <Heading
            as="h1"
            mb={8}
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            bgGradient="linear(to-r, teal.300, blue.500)"
            bgClip="text"
          >
            Mit tartalmaz a Wobble rendszer?
          </Heading>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_timeslot}
                alt="Időpontfoglaló rendszer felület, gyors és egyszerű foglalások"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Időpontfoglaló rendszer
              </Text>
              <Text mt={2} as="h2" color="black">
                A Wobble időpontfoglaló rendszere segít elkerülni a telefonos
                foglalásokat és várakozásokat. Könnyen használható felület, hogy
                a foglalás azonnal megtörténhessen
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              pt={16}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Box
                flex="1"
                mr={{ md: 8 }}
                mb={{ base: 10, md: 10 }}
                ml={{ md: 8 }}
                mt={{ md: 18 }}
              >
                <MonitorSectionFuncion imageUrl={website_dog} />
              </Box>
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Weboldal
              </Text>
              <Text mt={2} as="h2" color="black">
                Weboldalad megjelenését személyre szabhatod
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_dashboard}
                alt="Admin felület, minden eszközzel, amire szükséged van"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Admin felület
              </Text>
              <Text mt={2} as="h2" color="black">
                A Wobble admin felülete lehetővé teszi, hogy egy helyen kezeld
                az összes szolgáltatásodat és ügyfeledet, miközben naprakészen
                tartod az eseményeidet
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              pt={16}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              // bg="white"
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Box
                flex="1"
                mr={{ md: 8 }}
                mb={{ base: 10, md: 10 }}
                ml={{ md: 8 }}
                mt={{ md: 18 }}
              >
                <MonitorSectionFuncion imageUrl={mockup_blog} />
              </Box>
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Blog posztok
              </Text>
              <Text mt={2} as="h2" color="black">
                Oszd meg mi történik vállalkozásodban, készíts cikket
                weboldalaladra egy napodról, új szolgáltatásodról, sikereidről
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_domain}
                alt="Saját domain"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Saját domain
              </Text>
              <Text mt={2} as="h2" color="black">
                Használd a saját domain neved, vagy kapsz egy aldomain-t, az
                ingyenes SSL tanúsítványt mi adjuk
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_email}
                alt="Email marketing"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Marketing email
              </Text>
              <Text mt={2} as="h2" color="black">
                Küldj azonnali, vagy időzített emailt ügyfeleidnek, tájékoztasd
                klienseidet új szolgáltatáodról vagy kedvezményekről
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              // bg="white"
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_survey}
                alt="Email marketing"
                loading="lazy"
                w={{ base: "80%", md: "80%" }}
                mx="auto"
                mb={4}
                mt={12}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Egyedi kérdőív
              </Text>
              <Text mt={2} as="h2" color="black">
                Készíts egyedi kérdőívet a szolgáltatásodhoz, hogy már a
                foglaláskor minden fontos információt megtudhass az ügyfeledről.
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bgGradient="linear(to-r, teal.300, blue.500)"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <SwipeableImage />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Google Naptár szinkronizálási lehetőséggel
              </Text>
              <Text mt={2} as="h2" color="black">
                Szinkronizáld a Wobble foglalásaidat a Google naptáraddal, hogy
                könnyedén nyomon követhesd a találkozókat és eseményeket
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default FeatureSection;

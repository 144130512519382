import {
  Box,
  Container,
  Heading,
  Text,
  Image,
  VStack,
  Button,
  SimpleGrid,
  List,
  ListItem,
  AspectRatio,
} from "@chakra-ui/react";
import { mainColor } from "./landingPage/LandingPage";
import cover_image_mockup from "../assets/images/cover-image-mockup.png";
import SEO from "../SEO";

const EbookPage = () => {
  const scrollToForm = () => {
    const formElement = document.getElementById("form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Box py={16} m={4}>
      <SEO
        title="Ingyenes e-book: Online megjelenési lehetőségek kisvállalkozásoknak - Wobble"
        description="Tudd meg, milyen online megoldások léteznek vállalkozásod számára! Ingyenes e-bookunk segít eligazodni a weboldalak, foglalási rendszerek és online marketing világában."
        keywords="ingyenes e-book, online megjelenés, kisvállalkozás, weboldal készítés, foglalási rendszer, online marketing"
        ogTitle="Ingyenes e-book: Online megjelenési lehetőségek kisvállalkozásoknak"
        ogDescription="Hasonlítsd össze a WordPress, Webnode, Wobble és más megoldásokat! Töltsd le most ingyen!"
      />

      <Heading
        as="h1"
        fontSize={{ base: "2xl", md: "4xl" }}
        textAlign="center"
        color={mainColor}
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="wide"
        mb={6}
        lineHeight="1.2"
      >
        Ingyenes e-book, hogy digitálisan is helyt tudj állni
      </Heading>
      <Container
        maxW="container.lg"
        bgGradient="linear(to-br, #007188, #5AB3C5)"
        rounded={20}
        shadow="dark-lg"
        p={8}
      >
        <VStack spacing={8} align="center">
          <Box mx="auto">
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={8}
              alignItems="center"
              w="100%"
            >
              <Box>
                <Image
                  src={cover_image_mockup}
                  alt="Wobble e-book"
                  boxSize="auto"
                  maxW="100%"
                  borderRadius="lg"
                  boxShadow="xl"
                  transition="all 0.4s ease-in-out"
                  _hover={{
                    transform: "scale(1.1)",
                    boxShadow: "2xl",
                  }}
                />
              </Box>
              <Box>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color="gray.50"
                  mb={4}
                  lineHeight="1.8"
                  fontWeight="medium"
                >
                  Indítsd el vállalkozásodat magabiztosan! E-bookunk segít
                  eligazodni a digitális világban és megmutatja, hogyan
                  használhatod a legfontosabb eszközöket, hogy vállalkozásod
                  gyorsan növekedjen és látható legyen. Legyen szó weboldalról,
                  domain és tárhelyről vagy hatékony digitális eszközökről,
                  nálunk minden tippet megtalálsz, hogy sikeresebbé válj.
                </Text>
              </Box>
            </SimpleGrid>

            <Box textAlign="center" mt={6}>
              <Button
                colorScheme="blue"
                size="lg"
                w="auto"
                borderRadius="full"
                px={8}
                py={6}
                fontSize="lg"
                fontWeight="bold"
                onClick={scrollToForm}
                _hover={{
                  bg: "teal.400",
                  transform: "scale(1.05)",
                  boxShadow: "xl",
                }}
                _focus={{ outline: "none" }}
                transition="all 0.3s ease-in-out"
              >
                Letöltöm
              </Button>
            </Box>
          </Box>

          <Box textAlign="center" mt={12}>
            <Heading size="lg" mb={6} color="white" fontWeight="bold">
              Miért érdemes elolvasnod ezt az e-bookot?
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.50"
              mb={4}
              lineHeight="1.8"
              fontWeight="medium"
            >
              Képzeld el, hogy végre{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                kitanultál egy szuper szakmát
              </Text>{" "}
              , és most elérkezett az idő, hogy{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                saját vállalkozást indíts
              </Text>{" "}
              T. A tudásod és a szakértelmed megvan, de valahogy a digitális
              világ még mindig egy nagy, homályos ködnek tűnik. A net tele van
              tanácsokkal, de nem tudod, mi micsoda, vagy hogy hol kezd el. Ne
              aggódj, nem vagy ezzel egyedül! E-bookunk egy összeszedett,
              könnyen követhető digitális iránytűt ad a kezedbe, ami segít
              eligazodni a legfontosabb eszközök között.{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                Megmutatjuk, hogyan használhatod a digitális világot a
                vállalkozásod sikerére, hogy ne csak a szakmádba, hanem az
                online térbe is magabiztosan belépj.
              </Text>
            </Text>
            <List
              spacing={4}
              textAlign="left"
              maxW="xl"
              mx="auto"
              fontSize="lg"
              color="gray.50"
            >
              <ListItem>
                📌 Hogyan készíts könnyen kezelhető, professzionális weboldalt,
                ami megszólítja a célközönséget és nem kerül egy autó árába
              </ListItem>
              <ListItem>
                📌 Mit jelent a domain, tárhely, cookie és mire figyelj ha
                használod
              </ListItem>
              <ListItem>
                📌 Milyen digitális eszközök vannak és hogyan használhatod őket
                a vállalkozásod növekedéséhez
              </ListItem>
              <ListItem>
                📌 Milyen jogi lépéseket kell megtenned ha szolgáltatást vagy
                terméket kínálsz
              </ListItem>
              <ListItem>
                📌 Hogyan kerülheted el a leggyakoribb hibákat a digitális
                térben
              </ListItem>
              <ListItem>
                📌 Ajánlunk olyan megoldásokat, amelyek egy induló, kis
                költségvetésű vállalkozás számára is elérhetők
              </ListItem>
            </List>
            <Box textAlign="center" mt={6}>
              <Button
                colorScheme="blue"
                size="lg"
                w="auto"
                borderRadius="full"
                px={8}
                py={6}
                fontSize="lg"
                fontWeight="bold"
                onClick={scrollToForm}
                _hover={{
                  bg: "teal.400",
                  transform: "scale(1.05)",
                  boxShadow: "xl",
                }}
                _focus={{ outline: "none" }}
                transition="all 0.3s ease-in-out"
              >
                Kérem az ingyenes e-bookot
              </Button>
            </Box>
          </Box>

          <Box
            id="form"
            mt={8}
            textAlign="center"
            w={{ base: "100%", sm: "90%", md: "60%" }}
            maxW="500px"
            rounded="20px"
            overflow="hidden"
          >
            <AspectRatio
              ratio={1 / 3}
              w="100%"
              maxHeight={{ base: "800px", sm: "660px", md: "560px" }}
            >
              <iframe
                title="Responsive Iframe"
                src="https://app.wobble.hu/ebook"
                style={{
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                  display: "block",
                  border: "none",
                }}
              />
            </AspectRatio>
          </Box>

          <Box textAlign="center" mt={12}>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.50"
              mb={4}
              lineHeight="1.8"
              fontWeight="medium"
            >
              Ha úgy érzed, hogy nem elég csupán „csinálni” a vállalkozásod,
              hanem szeretnéd azt igazán jól csinálni, akkor ez az e-book neked
              szól.
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                Itt az ideje, hogy túllépj a középszerűségen,
              </Text>{" "}
              és valóban professzionálissá válj! Légy különb, felismerhető és
              emlékezetes - és ne hagyd, hogy a versenytársaid elorozzák azokat
              az ügyfeleket, akik téged is választhatnának.
            </Text>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.50"
              mb={4}
              lineHeight="1.8"
              fontWeight="medium"
            >
              Sokan úgy gondolják, hogy elég, ha tudják, mit csinálnak, aztán
              felrakják a Facebookra és várják az ügyfeleket/megrendeléseket.{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                Spoiler alert: nem jönnek.
              </Text>{" "}
              A digitális tér tele van versenytársakkal, és a potenciális
              vásárlóid már nem elégednek meg a minimumra. A titok nemcsak abban
              rejlik, hogy „kiválóan” csinálod a szakmádat, hanem hogy{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                képes vagy bizalmat építeni,
              </Text>{" "}
              és elérni, hogy az{" "}
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                ügyfelek téged válasszanak.
              </Text>
            </Text>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.50"
              mb={4}
              lineHeight="1.8"
              fontWeight="medium"
            >
              Ha nem vagy egy kicsivel jobb, láthatóbb, megbízhatóbb, akkor az
              ügyfeled könnyen elmegy a konkurenciához, aki rendelkezik egy
              szép, jól átlátható weboldallal, megfelelő nyitvatartással és
              profi képekkel. És igen, ilyen egyszerű. Ma már nem elegendő a
              „csak csinálom” mentalitás - az
              <Text as="span" fontWeight="bold" color="yellow.300">
                {" "}
                online sikerhez építeni kell egy olyan megjelenést, ami
                kiemelkedik a tömegből.
              </Text>
            </Text>
          </Box>
          <Box textAlign="center" mt={6}>
            <Button
              colorScheme="blue"
              size="lg"
              w="auto"
              borderRadius="full"
              px={8}
              py={6}
              fontSize="lg"
              fontWeight="bold"
              onClick={scrollToForm}
              _hover={{
                bg: "teal.400",
                transform: "scale(1.05)",
                boxShadow: "xl",
              }}
              _focus={{ outline: "none" }}
              transition="all 0.3s ease-in-out"
            >
              Letöltöm
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default EbookPage;

import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Button,
  useDisclosure,
  VStack,
  Link,
} from "@chakra-ui/react";
import cover_image_mockup from "../assets/images/cover-image-mockup.png";
import CornerBanner from "./landingPage/CornerBanner";

const AdModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      onOpen();
    }, 5000);
    return () => clearTimeout(timer);
  }, [onOpen]);

  const handleClose = () => {
    setIsBannerVisible(true);
    onClose(); // Properly close the modal
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        size={{ base: "sm", md: "md", lg: "lg" }}
      >
        <ModalOverlay bg="blackAlpha.700" />
        <ModalContent
          p={6}
          borderRadius="xl"
          boxShadow="2xl"
          bgGradient="linear(to-r, blue.500, teal.500)"
          color="white"
        >
          <ModalCloseButton color="white" />
          <ModalHeader textAlign="center" fontSize={{ base: "xl", md: "2xl" }}>
            🚀 Ingyenes digitális lépések eBook!
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="center">
              <Image
                src={cover_image_mockup}
                alt="Wobble eBook"
                w={{ base: "80%", md: "60%" }}
              />
              <Text
                fontSize={{ base: "md", md: "lg" }}
                textAlign="center"
                px={2}
                color="white"
              >
                Gratulálunk! Itt vagy, így valószínűleg vállalkozás indításán
                gondolkodsz, vagy már létre is hoztad.
              </Text>
              <Link href="/ebook" style={{ textDecoration: "none" }}>
                <Button
                  colorScheme="yellow"
                  size="lg"
                  px={8}
                  fontWeight="bold"
                  boxShadow="lg"
                  _hover={{ bg: "yellow.400" }}
                >
                  👉 Letöltöm az eBook-ot!
                </Button>
              </Link>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CornerBanner isVisible={isBannerVisible} />
    </>
  );
};

export default AdModal;

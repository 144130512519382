import { mainColor, darkColor, lightColor } from "./LandingPage";
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";

const FAQSection = () => {
  const color = mainColor;
  const borderColor = mainColor;
  const questionColor = darkColor;
  const hoverColor = "white";

  const faqs = [
    {
      question: "Mi a Wobble?",
      answer:
        "A Wobble egy olyan időpontfoglaló szoftver, amely egy rendszerben biztosítja a naptárkezelést, ügyfélkezelést, blogírást, e-mail küldést, egyedi kérdőívek készítését, valamint egyedi foglalási oldal és időpontfoglaló rendszert biztosít. Így segít egyszerűsíteni a vállalkozásod mindennapi működését és online jelenléted kezelését.",
    },
    {
      question: "Hogyan segít a Wobble a vállalkozásomon?",
      answer:
        "A Wobble egyszerűsíti az időpontfoglalási folyamatot, időt takarít meg mind a vállalkozónak, mind az ügyfeleinek, és segít javítani az online jelenlétét.",
    },
    {
      question: "A Wobble minden iparág számára megfelelő?",
      answer:
        "Minden olyan iparágban dolgozó vállalkozás számára alkalmas a Wobble, aki egy/egy szolgáltatást nyújt online vagy szolgáltató helyen, amihez időpont előzetes foglalása szükséges. Valamint azon vállalkozók számára, akik szeretnének egyetlen platformon digitális megoldásokkal egyszerűsíteni mindennapjaikat, ilyan pl. az email küldés, blog írás, vagy online megjelenés.",
    },
    {
      question: "Személyre szabhatom az időpontfoglaló oldalamat?",
      answer:
        "Természetesen! A Wobble lehetővé teszi, hogy személyre szabjad az időpontfoglaló oldalad, hogy illeszkedjen a márkád arculatához.",
    },
    {
      question: "Milyen támogatás érhető el, ha segítségre van szükségem?",
      answer:
        "Support csapatunk mindig rendelkezésre áll a kijelölt időpontokban ingyenes konzultációra, amit a https://book.wobble.hu oldalon lehet foglalni, vagy e-mailben az info@wobble.hu-ra. Ezen kívül blog cikkekben, videókban, Facebook és Instagram posztokban a lehető legjobban igyekszünk bemutatni a rendszert a könnyebb használat érdekében.",
    },
    {
      question: "Mennyibe kerül a teljes rendszer?",
      answer:
        "Két csomagunk van: Pro csomag, ami időpontfoglaló rendszert, online megjelenést, valamint a CRM rendszert biztosítja havi 2 999 Ft-ért, illetve a WebPro csomag, ami teljes weboldalt kínál beépített időpontfoglaló rendszerrel, valamint a CRM rendszert havi 5 899 Ft-ért. Éves előfizetésre is van lehetőség, ez esetben két hónapot mi állunk, így a Pro csomag 29 990 Ft, a WebPro csomag 58 990 Ft.",
    },
    {
      question: "Bármikor válthatok más csomagra?",
      answer:
        "Természetesen. Ha olyan csomagra szeretnél váltani, amelynek alacsonyabb az előfizetési díja, akkor az a következő számlázási ciklusban lép életbe. Magasabb szintű csomagra váltás esetén a módosítás azonnal életbe lép.",
    },
    {
      question: "Hogyan mondhatom le a csomagomat?",
      answer:
        "Szeretnénk, ha továbbra is velünk maradnál! Ha azonban a csomagod lemondása mellett döntesz, ezt a webes felületen, az Előfizetés menüpontban teheted meg. Az előfizetésed a jelenlegi számlázási ciklus (havi vagy éves) végén megszűnik, és ezt követően nem merül fel további költség.",
    },
  ];

  return (
    <Box id="gyik" color={color} py={10} px={4} maxW="6xl" mx="auto">
      <Heading as="h2" size="xl" textAlign="center" mb={8}>
        Gyakran ismételt kérdések (GYIK)
      </Heading>
      <Accordion allowToggle>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} borderColor={borderColor}>
            <AccordionButton
              h="60px"
              _hover={{
                bg: lightColor,
                color: hoverColor,
              }}
              _expanded={{
                bg: lightColor,
                color: mainColor,
              }}
            >
              <Box
                flex="1"
                textAlign="left"
                fontWeight="bold"
                color={questionColor}
                _hover={{
                  color: hoverColor,
                }}
              >
                {faq.question}
              </Box>
              <AccordionIcon
                color={questionColor}
                _hover={{
                  color: hoverColor,
                }}
              />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text as="h3">{faq.answer}</Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQSection;

import { Box, Button, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface CornerBannerProps {
  isVisible: boolean;
}

const CornerBanner: React.FC<CornerBannerProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <Box
      position="fixed"
      top="50px"
      right={-100}
      width="0"
      height="0"
      borderLeft="150px solid transparent" 
      borderRight="150px solid transparent" 
      borderBottom="150px solid  #5AB3C5" 
      zIndex={999}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      textAlign="center"
      transform="rotate(45deg)"
    >
      <Box
        position="absolute"
        top="50%"
        left="50%" 
        transform="translate(-49%, 60%) rotate(-45deg)"
        textAlign="center"
        width="max-content"
      >
        <Link as={RouterLink} to="/ebook" ml={4}>
          <Button
            size="sm"
            colorScheme="teal"
            transform="rotate(45deg)" // Keep button aligned with the triangle
          >
            Open
          </Button>
        </Link>
        <Text
         mt={4}
          fontSize="md"
          fontWeight="bold"
          color="white"
          marginBottom="10px"
          transform="rotate(45deg)"
        >
         Ingyenes e-book
        </Text>
      </Box>
    </Box>
  );
};

export default CornerBanner;

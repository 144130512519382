import React from "react";
import { Box, Icon, VStack } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { keyframes } from "@emotion/react";
import { FaMouse } from "react-icons/fa";

const pulseAnimation = keyframes`
  0% { transform: translateY(0); opacity: 1; }
  50% { transform: translateY(10px); opacity: 0.6; }
  100% { transform: translateY(0); opacity: 1; }
`;

const horizontalMove = keyframes`
  0% { transform: translateX(-15px); }
  50% { transform: translateX(15px); }
  100% { transform: translateX(-15px); }
`;

const ScrollingIcon: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="150px">
      <VStack spacing={3}>
        <Icon
          as={FaMouse}
          boxSize={8}
          color="gray.500"
          animation={`${horizontalMove} 2s ease-in-out infinite`}
        />
        <Icon
          as={ChevronDownIcon}
          boxSize={10}
          color="gray.600"
          animation={`${pulseAnimation} 1.5s ease-in-out infinite`}
        />
      </VStack>
    </Box>
  );
};

export default ScrollingIcon;
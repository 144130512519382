import calendar from "../assets/images/mockup_all_device.png";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import SEO from "../SEO";

const MediaSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SEO
        title="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        description="Egyszerű és gyors online időpontfoglaló rendszer és weboldal egyéni- és kisvállalkozásoknak."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-edzoknek.png"
      />
      <Box
        display="flex"
        justifyContent="space-between"
        p={4}
        maxW="1400px"
        mx="auto"
      >
        <Box position="relative" cursor="pointer">
          <Image
            src={calendar}
            alt="Responsív megjelenés, időpontfoglaló"
            width="100%"
            objectFit="cover"
            onClick={onOpen}
          />
        </Box>

        {/* <Box width="35%">
          <AspectRatio ratio={1}>
            <video
              src="https://cdn.millerproductions.hu/pointing.webm"
              autoPlay
              muted
              loop={true}
              playsInline
              onEnded={(e) => e.currentTarget.pause()}
              style={{ pointerEvents: "none", objectFit: "contain" }}
            />
          </AspectRatio>
        </Box> */}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="90vw"
          maxH="90vh"
          bg="transparent"
          boxShadow="none"
          position="relative"
        >
          <ModalCloseButton
            position="absolute"
            top="10px"
            right="10px"
            size="lg"
            color="white"
            bg="blackAlpha.700"
            _hover={{ bg: "blackAlpha.900" }}
          />
          <ModalBody
            p={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={calendar || ""}
              maxH="90vh"
              maxW="90vw"
              objectFit="contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MediaSection;

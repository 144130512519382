import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { greyColor, mainColor } from "./LandingPage";
import mockup from "../../assets/images/mockup_calendar_website.png";
import free_vibe from "../../assets/images/free_vibe.jpg";
import free_vibe2 from "../../assets/images/free_vibe2.jpeg"
import free_vibe3 from "../../assets/images/free_vibe3.jpg"
import free_vibe4 from "../../assets/images/free_vibe4.jpg"
import MainImages from "./MainImages";

const MainSection = () => {
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const bgSize = useBreakpointValue({
    base: "100%",
    md: "calc(100% + 50px)",
  }) as string;
  const bgPosition = useBreakpointValue({
    base: "center",
    md: "center",
    lg: "center",
  });

  const qaList = [
    {
      question:
        "Hogyan hozz létre professzionális online jelenlétet magas költségek nélkül?",
      answer:
        "Weboldal, időpontfoglaló és online eszközök - mindez egyszerűen, külön fejlesztő nélkül, a Wobble segítségével.",
      image: free_vibe,
    },
    {
      question: "Hogyan vonzz ügyfeleket és kezeld hatékonyan a foglalásaikat?",
      answer:
        "A Wobble időpontfoglaló rendszere leegyszerűsíti az ügyfélszerzést és a foglalások kezelését.",
      image: free_vibe2,
    },
    {
      question:
        "Hogyan maradj kapcsolatban az ügyfelekkel anélkül, hogy órákat töltenél marketinggel?",
      answer:
        "Beépített e-mail marketing és blogolási lehetőségek állnak rendelkezésedre, automatikusan.",
      image: free_vibe3,
    },
    {
      question:
        "Hogyan tudj meg minél több információt ügyfeledről már a foglaláskor?",
      answer:
        "Testreszabható foglalási űrlapokkal minden fontos információt előre megszerezhetsz.",
      image: free_vibe4,
    },
  ];

  return (
    <Box>
      <Flex
        position="relative"
        direction="column"
        align="center"
        justify="center"
        bg={greyColor}
        color="black"
        minH={{ base: "45vh", sm: "45vh", md: "85vh" }}
        clipPath="polygon(0 0, 100% 0, 100% 85%, 0 100%)"
        textAlign="center"
        px={4}
        py={{ base: 16, md: 24 }}
        bgSize="37px 37px"
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgImage={mockup}
          bgSize={bgSize}
          bgPosition={bgPosition}
          opacity={0.3}
          zIndex={0}
          bgRepeat="no-repeat"
        />
        <Heading
          as="h1"
          fontSize={{ base: "2xl", md: "4xl" }}
          color="black"
          zIndex={1}
        >
          Digitális megoldások szolgáltatóknak:
        </Heading>
        <Text
          fontSize={{ base: "md", md: "xl" }}
          mt={4}
          color="gray.700"
          zIndex={1}
        >
          Időpontfoglaló rendszer, online jelenlét és egyszerű ügyfélkezelés egy
          platformon.
        </Text>
        <Button
          as="a"
          href="https://app.wobble.hu/signup_page"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="teal"
          width="200px"
          m={8}
        >
          Kipróbálom most
          <ArrowForwardIcon />
        </Button>
      </Flex>

      <MainImages />

      <Box py={12} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" textAlign="center" mb={6}>
          A{" "}
          <Text as="span" color={mainColor} fontFamily="'Lato', sans-serif">
            Wobble
          </Text>{" "}
          segít gyorsan elindítani vállalkozásodat online időpontfoglaló
          rendszerrel és weboldallal!
        </Heading>

        <Stack spacing={{ base: 10, md: 16 }} mt={10}>
  {qaList.map((item, index) => (
    <Flex
      key={index}
      direction={{ base: "column", md: index % 2 === 0 ? "row" : "row-reverse" }}
      width="100%"
      align="center"
      gap={{ base: 6, md: 10 }}
    >
      {/* Image section (1/3 width) */}
      <Box
        w={{ base: "100%", md: "33.33%" }}
        borderRadius="2xl"
        overflow="hidden"
        boxShadow="md"
        flexShrink={0}
      >
        <Image
          src={item.image}
          alt={`Image for question ${index + 1}`}
          objectFit="cover"
          w="100%"
          h="100%"
          opacity={0.7}
          transition="opacity 0.3s ease"
          _hover={{ opacity: 0.85 }}
        />
      </Box>

      {/* Text section (2/3 width) */}
      <Box
        w={{ base: "100%", md: "66.66%" }}
        p={{ base: 4, md: 6 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={3}
      >
        <Heading
          as="h3"
          fontSize={{ base: "xl", md: "2xl" }}
          color="gray.800"
          lineHeight="1.3"
        >
          {item.question}
        </Heading>
        <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" lineHeight="1.7">
          {item.answer}
        </Text>
      </Box>
    </Flex>
  ))}
</Stack>


        <VStack spacing={4} align="center" mt={8}>
          <Text fontSize="lg" textAlign="center">
            Ne csak tervezz, próbáld ki ingyen 30 napig!
          </Text>
          <Link href="https://app.wobble.hu/signup_page" isExternal>
            <Button colorScheme="teal" size={buttonSize} px={10}>
              Regisztrálj és próbáld ki <ArrowForwardIcon />
            </Button>
          </Link>
        </VStack>
      </Box>
    </Box>
  );
};

export default MainSection;

import embedded from "../assets/blog_images/embedded/embedded.png";
import link from "../assets/blog_images/embedded/link.png";
import wix_page from "../assets/blog_images/embedded/wix_page.png";
import {
  Box,
  Heading,
  Text,
  Image,
  SimpleGrid,
  VStack,
  Flex,
  Icon,
  Divider,
  Container,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaCodeBranch } from "react-icons/fa";
import { motion } from "framer-motion";
import { darkColor, mainColor } from "./landingPage/LandingPage";
import SEO from "../SEO";

const MotionBox = motion(Box);
const MotionImage = motion(Image);

const EmbeddedSection = () => {
  const ctaSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <>
      <SEO
        title="Időpontfoglaló rendszer weboldalhoz - Beágyazható online foglalási megoldás | Wobble"
        description="Szeretnél időpontfoglaló rendszert a weboldaladba? A Wobble egyszerűen beágyazható online időpontfoglaló megoldást kínál egyéni- és kisvállalkozásoknak. Integráld néhány kattintással - akár Wix, WordPress vagy más weboldal esetén is."
        keywords="időpontfoglaló rendszer weboldalhoz, beágyazható időpontfoglaló, időpontfoglaló integrálása weboldalba, online foglalási rendszer, foglalási modul weboldalhoz, időpontfoglaló kisvállalkozásoknak, foglalási rendszer Wix, időpontfoglaló WordPress, online időpontfoglaló megoldás"
        ogTitle="Beágyazható időpontfoglaló rendszer weboldaladhoz - Wobble"
        ogDescription="A Wobble időpontfoglaló rendszer könnyen integrálható weboldaladba - tökéletes kisvállalkozásoknak, akik gyors és kényelmes foglalási lehetőséget szeretnének nyújtani."
        ogImage="https://wobble.hu/images/assets/sajat-domain.png"
      />
      <Box py={{ base: 12, md: 20 }} position="relative" overflow="hidden">
        {/* Background decorative symbol */}
        <MotionBox
          position="absolute"
          top="10%"
          left="-10%"
          opacity={0.05}
          fontSize="9xl"
          fontWeight="bold"
          color="purple.300"
          zIndex={0}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 0.05 }}
          transition={{ duration: 1.5 }}
        >
          &lt;/&gt;
        </MotionBox>

        <Container
          maxW="7xl"
          px={{ base: 4, md: 8 }}
          position="relative"
          zIndex={1}
        >
          <VStack spacing={10} align="center" textAlign="center">
            {/* Decorative Icon */}
            <Flex align="center" justify="center" mb={2}>
              <Icon as={FaCodeBranch} boxSize={10} color={mainColor} />
            </Flex>

            {/* Title */}
            <Heading
              as="h1"
              fontSize={{ base: "3xl", md: "5xl" }}
              fontWeight="extrabold"
              bgGradient="linear(to-r, teal.300, blue.500)"
              bgClip="text"
            >
              Egyszerűsítsd az időpontfoglalást a weboldaladon!
            </Heading>

            {/* Main Text */}
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.600"
              maxW="3xl"
            >
              Ha már van weboldalad, és szeretnéd könnyebbé tenni az
              időpontfoglalást az ügyfeleid számára, akkor jó híreink vannak! A
              Wobble időpontfoglaló rendszere egyszerűen integrálható bármilyen
              platformra - legyen az WordPress, Systeme.io, vagy saját
              fejlesztésű oldal.
            </Text>

            {/* Additional Text Section */}
            <Text
              fontSize={{ base: "sm", md: "md" }}
              color="gray.500"
              maxW="2xl"
            >
              Az új beágyazás weboldalba két lehetőség közül választhatsz:
              iframe vagy gomb link. Még könnyebbé tesszük a foglalást!
            </Text>

            <Divider borderColor="gray.300" maxW="6xl" />

            {/* Image and Text Box Grid */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
              <MotionBox
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <MotionImage
                  src={embedded}
                  alt="Embedded Visual 1"
                  borderRadius="2xl"
                  objectFit="cover"
                  shadow="xl"
                  w="full"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.6 }}
                />
                <Text mt={3} fontSize="sm" color="gray.600" textAlign="center">
                  Foglalási rendszer pár kattintással - már meglévő
                  weboldaladhoz is!
                </Text>
                <Box
                  mt={4}
                  p={5}
                  borderWidth={1}
                  borderRadius="md"
                  borderColor="gray.200"
                  bgColor="gray.50"
                  maxW="xl"
                  mx="auto"
                >
                  <Text fontSize="lg" color="gray.700" mb={4}>
                    Már van weboldalad, és csak egy modern időpontfoglaló
                    rendszer hiányzik? Nem kell új megoldást keresned - használd
                    a beágyazható iframe kódot, és a rendszer azonnal megjelenik
                    az oldaladon. Gyorsan integrálható, technikai tudás nélkül
                    is pár perc alatt működik.
                  </Text>
                </Box>
              </MotionBox>

              <MotionBox
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <MotionImage
                  src={link}
                  alt="Embedded Visual 2"
                  borderRadius="2xl"
                  objectFit="cover"
                  shadow="xl"
                  w="full"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.6 }}
                />
                <Text mt={3} fontSize="sm" color="gray.600" textAlign="center">
                  Gomb link
                </Text>
                <Box
                  mt={4}
                  p={5}
                  borderWidth={1}
                  borderRadius="md"
                  borderColor="gray.200"
                  bgColor="gray.50"
                  maxW="xl"
                  mx="auto"
                >
                  <Text fontSize="lg" color="gray.700" mb={4}>
                    Ha nem akarod az egész foglalási felületet beágyazni, és
                    csak egy gombot szeretnél, ami egy új oldalon megnyitja a
                    foglalási oldalt, egyszerűen használd a foglalási linkedet a
                    gombhoz.
                  </Text>
                </Box>
              </MotionBox>
            </SimpleGrid>

            <MotionBox
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              w="full"
            >
              <MotionImage
                src={wix_page}
                alt="Custom Integration"
                borderRadius="2xl"
                objectFit="cover"
                shadow="xl"
                maxW="3xl"
                w="full"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              />
              <Text mt={3} fontSize="sm" color="gray.600" textAlign="center">
                Demo Wix oldal beágyazott Wobble időpontfoglalóval
              </Text>
              <Box
                mt={4}
                p={5}
                borderWidth={1}
                borderRadius="md"
                borderColor="gray.200"
                bgColor="gray.50"
                maxW="3xl"
                mx="auto"
              >
                <Text fontSize="lg" color="gray.700" mb={4}>
                  A képen egy szépségápolási Wix demo oldalon láthatod, milyen
                  elegánsan illeszkedik a Wobble időpontfoglaló rendszer. Ne
                  hagyd, hogy ügyfeleid csak nézelődjenek - alakítsd át őket
                  foglaló vendégekké! Ha már a weboldaladon járnak, tedd
                  számukra egyszerűvé: néhány kattintással, telefonálás nélkül
                  foglalhatnak időpontot.
                </Text>
              </Box>
            </MotionBox>

            <Button
              as="a"
              href="https://wobble.hu/blog/hogyan-agyazd-be-a-wobble-idopontfoglalot-a-sajat-weboldaladba"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ bg: darkColor, textDecoration: "none" }}
              size={ctaSize}
              colorScheme="teal"
              mt={10}
              px={8}
              py={6}
              rounded="2xl"
              shadow="lg"
              fontWeight="bold"
            >
              Blog cikk a témában
            </Button>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export default EmbeddedSection;

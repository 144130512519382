import React, { useState } from "react";
import {
  Box,
  IconButton,
  Flex,
  Heading,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import mockup_doctor from "../assets/images/mockup_docktor.png";
import mockup_beauty from "../assets/images/mockup_beauty.png";
import mockup_gym from "../assets/images/mockup_gym.png";
import mockup_photographer from "../assets/images/mockup_photographer.png";
import mockup_teacher from "../assets/images/mockup_teacher.png";
import mockup_coach from "../assets/images/mockup_coach.png";
import { darkColor } from "./landingPage/LandingPage";
import Section from "./landingPage/Section";
import SEO from "../SEO";

const images = [
  {
    src: mockup_doctor,
    label: "Fogorvosoknak, orvosoknak, pszichológusoknak",
    color: "linear(to-b, gray.50, gray.500)",
  },
  {
    src: mockup_beauty,
    label:
      "Kozmetikusoknak, masszőröknek, fodrászoknak, manikűrösöknek, pedikűrösöknek, sminkeseknek",
    color: "linear(to-b, pink.50, pink.500)",
  },
  {
    src: mockup_gym,
    label: "Edzőknek, trénereknek",
    color: "linear(to-b, green.100, green.500)",
  },
  {
    src: mockup_photographer,
    label: "Fotósoknak, videósoknak",
    color: "linear(to-b, red.400, gray.400)",
  },
  {
    src: mockup_teacher,
    label: "Magántanároknak",
    color: "linear(to-b, teal.500, green.100)",
  },
  {
    src: mockup_coach,
    label: "Tanácsadóknak, konzultációt tartóknak, coachoknak",
    color: "linear(to-b, blue.50, blue.500)",
  },
];

const ImageSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesPerView = useBreakpointValue({ base: 1, md: 2, lg: 3 }) ?? 1;

  const handlePrev = () => setCurrentIndex((prev) => Math.max(prev - 1, 0));
  const handleNext = () =>
    setCurrentIndex((prev) =>
      Math.min(prev + 1, images.length - imagesPerView)
    );

  return (
    <>
      <SEO
        title="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        description="Egyszerű és gyors online időpontfoglaló rendszer és weboldal egyéni- és kisvállalkozásoknak."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-szepsegiparban.png"
      />
      <Flex
        id="usage"
        direction="column"
        align="center"
        justify="center"
        bg="gray.50"
        mx="auto"
        py={10}
        px={4}
      >
        <Heading
          as="h1"
          mb={8}
          fontSize={{ base: "3xl", md: "5xl" }}
          fontWeight="bold"
          bgGradient="linear(to-r, teal.300, blue.500)"
          bgClip="text"
        >
          Kiknek ajánljuk?
        </Heading>
        <Box p={{ base: 8, md: 10 }} maxW={1200}>
          <Text
            fontSize={{ md: 24 }}
            color={darkColor}
            pt={{ base: 8, md: 10 }}
            as="h3"
          >
            📌 Elsősorban olyan vállalkozóknak ajánljuk, akik most indítják el
            vállalkozásukat, még nincs weboldaluk, és szükségük van online
            megjelenésre, időpontfoglaló rendszerre, valamint ügyfélkezelési
            megoldásra.
          </Text>
          <Text
            fontSize={{ md: 24 }}
            color={darkColor}
            pt={{ base: 8, md: 10 }}
            as="h3"
          >
            📌 Emellett olyanoknak is ideális, akiknek nincs online jelenlétük a
            közösségi médián kívül, és hasznos lehet számukra egy olcsón
            fenntartható weboldal, amely minden szükséges információt és
            időpontfoglaló rendszert tartalmaz.
          </Text>
          <Text
            fontSize={{ md: 24 }}
            color={darkColor}
            pt={{ base: 8, md: 10 }}
            as="h3"
          >
            📌 Tehát mindazoknak, akik ügyfelekkel dolgoznak, és fontos számukra
            a folyamatos elérhetőség, a kapcsolattartás és a foglalások
            kezelése.
          </Text>
        </Box>
        <Flex align="center" w="100%" position="relative">
          <IconButton
            aria-label="Previous"
            icon={<ChevronLeftIcon boxSize={8} />}
            onClick={handlePrev}
            isDisabled={currentIndex === 0}
            position="absolute"
            left={-6}
            top="50%"
            transform="translateY(-50%)"
            variant="ghost"
            size="lg"
            zIndex={1}
          />
          <Flex
            overflow="hidden"
            w="100%"
            borderRadius="2xl"
            bg="gray.50"
            px={2}
          >
            <Flex
              w="100%"
              transform={`translateX(-${
                (100 / imagesPerView) * currentIndex
              }%)`}
              transition="transform 0.6s ease"
            >
              {images.map((image, index) => (
                <Box
                  key={index}
                  flex={`0 0 calc(100% / ${imagesPerView})`}
                  p={4}
                  textAlign="center"
                >
                  <Box
                    borderRadius="xl"
                    boxShadow="xl"
                    overflow="hidden"
                    bgGradient={image.color}
                    p={4}
                  >
                    <Section
                      imageSrc={image.src}
                      text={image.label}
                      reverse={true}
                      imageAlt={image.label}
                      bgColor={image.color}
                    />
                  </Box>
                </Box>
              ))}
            </Flex>
          </Flex>
          <IconButton
            aria-label="Next"
            icon={<ChevronRightIcon boxSize={8} />}
            onClick={handleNext}
            isDisabled={currentIndex >= images.length - imagesPerView}
            position="absolute"
            right={-6}
            top="50%"
            transform="translateY(-50%)"
            variant="ghost"
            size="lg"
            zIndex={1}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default ImageSlider;

import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  SimpleGrid,
  Container,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { mainColor } from "./landingPage/LandingPage";
import CircleWithNumber from "./landingPage/CircleWithNumber";
import image from "../assets/images/3stepsImage.png";
import { steps_image1 } from "../assets/images/steps_image1";
import { steps_image2 } from "../assets/images/steps_image2";
import { steps_image3 } from "../assets/images/steps_image3";
import booking from "../assets/images/mockup_beauty.png";
import admin from "../assets/images/mockup_dashboard.png";
import solutions from "../assets/images/mockup_customer_survey.png";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@chakra-ui/icons";
import SEO from "../SEO";

const MotionBox = motion(Box);
const MotionHStack = motion(HStack);

const images = [
  {
    src: booking,
    alt: "Időpontfoglaló rendszer",
    text: "Online időpontfoglaló rendszer",
  },
  { src: admin, alt: "Admin felület", text: "Admin felület" },
  { src: solutions, alt: "Digitális megoldások", text: "Digitális megoldások" },
];

const listItems = [
  "Online időpontfoglaló rendszer",
  "Korlátlan szolgáltatás",
  "Korlátlan foglalás",
  "Email emlékeztető",
  "Vállalkozást bemutató tab",
  "Ügyfélértékelések",
  "Egyedi kérdőív foglaláskor",
  "Marketing email küldés",
  "Referencia képek",
];

const BookingSystemSection = () => {
  const displayMode = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  return (
    <>
      <SEO
        title="Online időpontfoglaló rendszer"
        description="Hozz létre saját foglalási oldalt, és érd el könnyedén a vállalkozásod online jelenlétét"
        keywords="weboldal készítés, online időpontfoglaló, reszponzív design, korlátlan szolgáltatás, weboldal sablon, email emlékeztető, blog írás, ügyfélértékelések"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-fotosoknak.png"
      />

      <Container maxW="container.xl" py={16} textAlign="center">
        <Heading
          mb={8}
          fontSize={{ base: "3xl", md: "5xl" }}
          fontWeight="bold"
          bgGradient="linear(to-r, teal.300, blue.500)"
          bgClip="text"
        >
          Online időpontfoglaló rendszer
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={8}
          justifyItems="center"
        >
          <SimpleGrid row={{ base: 1, md: 1 }} spacing={8} maxW={400}>
            {images.map((item, index) => (
              <MotionBox
                key={index}
                p={6}
                bgGradient="linear(to-b, blue.900, blue.600)"
                borderRadius="xl"
                boxShadow="2xl"
                backdropFilter="blur(10px)"
                whileHover={{ scale: 1.1, rotate: 2 }}
                transition={{ duration: 0.4 }}
              >
                <Image src={item.src} alt={item.alt} borderRadius="lg" mb={4} />
                <Text fontSize="xl" fontWeight="semibold" color="white">
                  {item.text}
                </Text>
              </MotionBox>
            ))}
          </SimpleGrid>
          <VStack mt={10} spacing={4} align="center">
            {listItems.map((item, index) => (
              <MotionHStack
                key={index}
                bg="blue.600"
                p={4}
                borderRadius="lg"
                boxShadow="lg"
                backdropFilter="blur(10px)"
                maxW="lg"
                w="full"
                align="center"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <Icon as={CheckCircleIcon} w={6} h={6} color="teal.400" />
                <Text fontSize="lg" color="white" fontWeight="medium">
                  {item}
                </Text>
              </MotionHStack>
            ))}
          </VStack>
        </SimpleGrid>
      </Container>

      <Box
        id="integration"
        p={{ base: 8, md: 8, lg: 12 }}
        position="relative"
        width="100%"
        bg="white"
        scrollBehavior="smooth"
      >
        <Box
          position="relative"
          backgroundSize="cover"
          backgroundPosition="center"
          zIndex={2}
          w="100%"
          py={8}
        >
          <Center>
            <Heading textAlign="center" color={mainColor} as="h1">
              <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
                Hogyan működik?
              </Text>
              Online időpontfoglalás egyszerűen, 3 lépéses beállítással
            </Heading>
          </Center>
        </Box>
        <Box
          display={displayMode === "desktop" ? "block" : "flex"}
          flexDirection={displayMode === "tablet" ? "row" : "column"}
          alignItems="center"
          justifyContent="center"
        >
          {displayMode === "desktop" && (
            <Box
              position="relative"
              backgroundSize="cover"
              backgroundPosition="center"
              zIndex={2}
            >
              <Center>
                <Image src={image} alt="3 steps" />
              </Center>
            </Box>
          )}
          {displayMode !== "desktop" && (
            <Box p={4}>
              <Box
                display={displayMode === "tablet" ? "flex" : "block"}
                flexDirection={displayMode === "tablet" ? "row" : "column"}
                alignItems="left"
                justifyContent="left"
                mb={4}
              >
                <Box w={"70%"}>{steps_image1}</Box>
                <Stack>
                  <HStack>
                    <CircleWithNumber number={1} />
                    <Text
                      mt={displayMode !== "tablet" ? 4 : 0}
                      fontSize={displayMode === "tablet" ? 18 : 24}
                      textAlign="left"
                      width={{ base: "80%", md: "auto" }}
                    >
                      lépés: Állítsa be az elérhetőségét
                    </Text>
                  </HStack>
                  <Text
                    ml={displayMode === "tablet" ? 4 : 0}
                    mt={displayMode !== "tablet" ? 4 : 0}
                  >
                    Hozd létre az általad kínált szolgáltatásokat és állítsd be
                    az időintervallumokat. Annyi szolgáltatást adhatsz meg,
                    amennyit csak szeretnél, és a valós ütemtervedhez igazodva
                    egyedi órákat is beállíthatsz. Nyugi, rendszerünk megelőzi a
                    dupla foglalásokat!
                  </Text>
                </Stack>
              </Box>
              <Box
                display={displayMode === "tablet" ? "flex" : "block"}
                flexDirection={displayMode === "tablet" ? "row" : "column"}
                alignItems="left"
                justifyContent="left"
                mb={4}
                h="100%"
              >
                <Box w={displayMode === "tablet" ? "30%" : "50%"}>
                  {steps_image2}
                </Box>
                <Stack>
                  <HStack>
                    <CircleWithNumber number={2} />
                    <Text
                      mt={displayMode !== "tablet" ? 4 : 0}
                      fontSize={displayMode === "tablet" ? 18 : 24}
                      textAlign="left"
                      width={{ base: "80%", md: "auto" }}
                    >
                      lépés: Oszd meg a foglalási linkedet
                    </Text>
                  </HStack>
                  <Text
                    ml={displayMode === "tablet" ? 4 : 0}
                    mt={displayMode !== "tablet" ? 4 : 0}
                  >
                    Egyszerűen oszd meg a foglalási oldalad URL-jét az
                    ügyfelekkel.
                  </Text>
                </Stack>
              </Box>
              <Box
                display={displayMode === "tablet" ? "flex" : "block"}
                flexDirection={displayMode === "tablet" ? "row" : "column"}
                alignItems="left"
                justifyContent="left"
                mb={4}
              >
                <Box w={"70%"}>{steps_image3}</Box>
                <Stack>
                  <HStack>
                    <CircleWithNumber number={3} />
                    <Text
                      mt={displayMode !== "tablet" ? 4 : 0}
                      fontSize={displayMode === "tablet" ? 18 : 24}
                      textAlign="left"
                      width={{ base: "80%", md: "auto" }}
                    >
                      lépés: Élvezd a problémamentes online foglalásokat
                      éjjel-nappal (az emberek 55%-a munkaidőn kívül foglal)
                    </Text>
                  </HStack>
                  <Text
                    ml={displayMode === "tablet" ? 4 : 0}
                    mt={displayMode !== "tablet" ? 4 : 0}
                  >
                    Biztosítsd az ügyfeleknek a lehetőséget, hogy bármikor
                    foglalhassanak vagy lemondhassák az időpontokat. Rendszerünk
                    automatikusan küld e-mail emlékeztetőket, így ritkábbak
                    lesznek az elmaradt időpontok és zökkenőmentes lesz az
                    ütemterved.
                  </Text>
                </Stack>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BookingSystemSection;

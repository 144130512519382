import { FC, ReactNode } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { FaPlus, FaCheck } from "react-icons/fa";
import { mainColor } from "../landingPage/LandingPage";
import BonusList from "./BonusList";

type PriceCardProps = {
  packageName: string;
  monthlyPrice: ReactNode;
  annualPrice: ReactNode;
  features: string[];
  isAnnual: boolean;
  buttonLink: string;
  bonusList: string[];
};

const PriceCard: FC<PriceCardProps> = ({
  packageName,
  monthlyPrice,
  annualPrice,
  features,
  isAnnual,
  buttonLink,
  bonusList,
}) => {
  return (
    <Box
      w={{ base: "100%", md: "45%" }}
      p={4}
      bg="white"
      borderRadius="lg"
      boxShadow="md"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <Heading size="lg" color={mainColor}>
          {isAnnual ? `${packageName} Éves` : `${packageName} Havi`}
        </Heading>
      </Box>
      <Box
        shadow="lg"
        p={6}
        borderRadius="md"
        borderWidth={1}
        borderColor="gray.200"
        mb={4}
      >
        <Flex direction="column" alignItems="center">
          <Text as="b" color="brand.2" fontSize="3xl">
            {isAnnual ? annualPrice : monthlyPrice}
          </Text>
          <Text fontSize="2xl" pt={4}>
            {isAnnual ? "Évente" : "Havonta"}
          </Text>
          <Text color="brand.2" pt={4}>
            Az ár tartalmazza az ÁFA-t.
          </Text>
        </Flex>
        <Divider my={4} />
        <Box
          as="button"
          height="40px"
          w="100%"
          transition="all 0.2s"
          border="1px"
          borderColor="brand.1"
          borderRadius="8px"
          fontSize="14px"
          fontWeight="semibold"
          bg="brand.1"
          _hover={{ bg: "brand.2" }}
          _active={{
            bg: "brand.2",
            transform: "scale(0.98)",
            borderColor: "brand.2",
          }}
          _focus={{
            boxShadow: "0 0 1px 2px rgba(88, 144, 255, .75)",
          }}
          onClick={() => window.open(buttonLink, "_blank")}
        >
          Kipróbálom
        </Box>
      </Box>

      {/* Benefits section */}
      <Box>
        <Heading size="md" mb={4}>
          Csomag tartalma:
        </Heading>
        <List spacing={3}>
          {packageName === "WebPro Csomag" ? (
            <>
              <ListItem>
                <ListIcon as={FaCheck} color="green.500" />
                Minden, ami a Pro csomagban található ❗️
              </ListItem>
              <Divider />
            </>
          ) : null}
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              {feature}
            </ListItem>
          ))}
        </List>
        <Box>
          <BonusList title="Bónusz:" items={bonusList} icon={FaPlus} />
        </Box>
      </Box>
    </Box>
  );
};

export default PriceCard;

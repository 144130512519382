import {
  Box,
  VStack,
  Image,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { lightColor } from "./landingPage/LandingPage";
import partnership from "../assets/images/partnership.png";
import SEO from "../SEO";

const PartnershipSection = () => {
  return (
    <>
     <SEO
        title="Emelje képzéseit a digitális kor színvonalára a Wobble időpontfoglaló
        rendszerével!"
        description="Fedezze fel, hogyan integrálhatja a Wobble időpontfoglaló rendszert képzéseibe, hogy hallgatói magabiztosan és felkészülten vághassanak bele szakmai karrierjükbe."
        keywords="időpontfoglaló, weboldal, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        ogTitle="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        ogDescription="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-edzoknek.png"
      />
      <Box
        bg="gray.50"
        py={12}
        px={6}
        w="full"
        textAlign="center"
      >
        <VStack
          spacing={6}
          maxW="5xl"
          mx="auto"
          bg="white"
          boxShadow="lg"
          borderRadius="lg"
          overflow="hidden"
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.02)" }}
        >
          <Image
            src={partnership}
            alt="wobble partneri kapcsolat"
            objectFit="cover"
            w="100%"
            h="auto"
          />
          <Box p={8} textAlign="left">
            <Heading
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="bold"
              mb={4}
              color="gray.800"
            >
              Emelje képzéseit a digitális kor színvonalára a Wobble
              időpontfoglaló rendszerével!
            </Heading>
            <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={4}>
              A mai szolgáltatói szektorban - legyen szó fodrászokról,
              masszőrökről vagy coachokról - a szakmai tudás mellett
              elengedhetetlen a digitális eszközök és technológiák magabiztos
              használata. Azonban sok képzés még mindig nem fordít kellő
              figyelmet erre a területre, így a frissen végzett szakemberek
              versenyhátrányba kerülhetnek.
            </Text>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color="gray.600"
              mb={4}
              fontWeight="bold"
            >
              Miért érdemes a Wobble rendszerét integrálni képzéseibe?
            </Text>
            <UnorderedList spacing={3} mb={6} color="gray.600">
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Modern időpontfoglalás:
                </Text>{" "}
                A Wobble rendszerével hallgatói elsajátíthatják az online
                időpontfoglalás kezelését, amely ma már alapvető elvárás az
                ügyfelek részéről.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Online jelenlét és weboldal fontossága:
                </Text>{" "}
                A digitális világban a weboldal és az online jelenlét alapvető
                fontosságú a szolgáltatók számára. A Wobble rendszerével
                hallgatói könnyedén létrehozhatják saját honlapjukat és online
                időpontfoglaló felületüket, amely nemcsak a szolgáltatásuk
                népszerűsítésében segít, hanem a potenciális ügyfelek számára is
                könnyen elérhetővé teszi őket. Ezáltal a hallgatók
                versenyelőnyre tehetnek szert a piacon.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Hatékony ügyfélkezelés:
                </Text>{" "}
                A rendszer használatával a leendő szakemberek megtanulják,
                hogyan kezeljék egyszerűen és hatékonyan ügyféladataikat,
                növelve ezzel szolgáltatásuk színvonalát.
              </ListItem>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  Versenyképes oktatási csomagok:
                </Text>{" "}
                A Wobble integrálásával képzései vonzóbbá válnak a digitális
                megoldások iránt érdeklődő diákok számára, így növelheti
                intézménye versenyképességét.
              </ListItem>
            </UnorderedList>
            <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={6}>
              A Wobble célja, hogy támogassa azokat a szolgáltatókat, akik
              kiválóak a szakmájukban, de a digitális térben nem érzik magukat
              otthonosan, vagy nem rendelkeznek a szükséges tudással és
              erőforrásokkal a digitális jelenlét kialakításához. Segítünk nekik
              abban, hogy könnyedén és költséghatékonyan építhessék fel online
              jelenlétüket, így elérhetik a szélesebb közönséget és növelhetik
              üzleti lehetőségeiket.
            </Text>
            <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={6}>
              Ne hagyja, hogy képzései lemaradjanak a digitális forradalomban!
              Lépjen velünk kapcsolatba, és tegyük együtt értékesebbé oktatási
              programjait a Wobble rendszerének bevezetésével, hogy hallgatói
              magabiztosan és felkészülten vághassanak bele szakmai
              karrierjükbe.
            </Text>
            <Button
              as="a"
              href="https://book.wobble.hu"
              target="_blank"
              rel="noopener noreferrer"
              colorScheme="teal"
              textDecoration="none"
              _hover={{ bg: lightColor, textDecoration: "none" }}
            >
              Vegye fel velünk a kapcsolatot!
            </Button>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default PartnershipSection;

import {
  Box,
  Stack,
  Text,
  Link,
  Flex,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import logo from "../assets/logo/logo_white.png";
import { lightColor } from "./landingPage/LandingPage";
import { Link as ImageLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box bg="gray.900" color="gray.200" py={10}>
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={6}
        flexWrap="wrap"
        direction={["column", "column", "row"]}
      >
        <Stack
          direction={["column", "column", "row"]}
          spacing={4}
          mb={[6, 6, 0]}
          textAlign={["center", "center", "left"]}
          width={["100%", "100%", "auto"]}
        >
          {[
            { href: "/", label: "Főoldal" },
            { href: "/funkciok", label: "Funkciók" },
            { href: "/online-idopontfoglalo", label: "Időpontfoglaló" },
            { href: "/weboldal", label: "Weboldal" },
            { href: "/kiknek", label: "Kiknek?" },
            { href: "/csomagok", label: "Árak" },
            { href: "partner", label: "Partnerség" },
          ].map(({ href, label }) => (
            <Link
              key={href}
              href={href}
              _hover={{ textDecoration: "underline", color: "white" }}
            >
              {label}
            </Link>
          ))}
        </Stack>

        <Stack
          direction={["column", "column", "row"]}
          spacing={4}
          textAlign={["center", "center", "left"]}
          width={["100%", "100%", "auto"]}
          mb={[6, 6, 0]}
        >
          <Link
            href="https://app.wobble.hu/adatvedelem"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Adatkezelési tájékoztató
          </Link>
          <Link
            href="https://app.wobble.hu/aszf"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Általános Szerződési Feltételek
          </Link>
        </Stack>

        <Stack
          direction={["column", "column", "row"]}
          spacing={4}
          align={["center", "center", "flex-start"]}
          width={["100%", "100%", "auto"]}
          mb={[4, 4, 0]}
          mt={4}
        >
          <ImageLink to="/"><Image src={logo} alt="Logo" h="50px" objectFit="contain" mb={[4, 0]} /></ImageLink>
          <Stack direction="row" spacing={4} justify={["center", "center", "flex-start"]}>
            <IconButton
              icon={<FaFacebook />}
              as="a"
              href="https://www.facebook.com/people/Wobble-App/61566480490390/"
              aria-label="Facebook"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
            <IconButton
              as="a"
              icon={<FaInstagram />}
              href="https://www.instagram.com/wobble_app/"
              aria-label="Instagram"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
            <IconButton
              icon={<FaYoutube />}
              as="a"
              href="https://www.youtube.com/@WobbleAppHun/videos"
              aria-label="YouTube"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
          </Stack>
        </Stack>
      </Flex>

      {/* Footer Text */}
      <Box mt={8} textAlign="center">
        <Text fontSize="sm" color={lightColor}>
          &copy; {new Date().getFullYear()} Miller Productions. Minden jog fenntartva.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;

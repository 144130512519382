import dog_web from "../assets/images/weboldal-kutyakozmetika.png";
import clean_web from "../assets/images/weboldal-takaitoceg.png";
import beauty_classic from "../assets/images/lumiere-szalon-classic.png";
import beauty_default from "../assets/images/lumiere-szalon-default.png";

import React from "react";
import {
  Box,
  Heading,
  Text,
  useBreakpointValue,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import ScrollingIcon from "./landingPage/ScrollingIcon";
import MonitorSection from "./MonitorSection";
import { motion } from "framer-motion";
import SEO from "../SEO";

const listItems = [
  "Reszponzív weboldal minden eszközre",
  "Online időpontfoglaló rendszer",
  "Korlátlan szolgáltatás",
  "Korlátlan foglalás",
  "Email emlékeztető",
  "Saját domain használata",
  "Blog írás, blog oldalak",
  "Szolgáltatást bemutató oldalak",
  "Ügyfélértékelések",
  "Egyedi kérdőív foglaláskor",
  "Marketing email küldés",
  "Referencia képek",
  "Weboldal sablon, amit személyre szabhatsz. Hamarosan érkezik a következő sablon!",
];

const WebsiteSection: React.FC = () => {
  const sectionWidth = useBreakpointValue({
    base: "100%",
    md: "90%",
  }) as string;

  return (
    <>
     <SEO
        title=" Online weboldal és időpontfoglaló rendszer - készítsd el saját
        weboldalad!"
        description="Készítsd el saját, reszponzív weboldaladat online időpontfoglaló rendszerrel, korlátlan szolgáltatásokkal, saját domainnel és egyedi sablonokkal."
        keywords="weboldal készítés, online időpontfoglaló, reszponzív design, korlátlan szolgáltatás, weboldal sablon, email emlékeztető, blog írás, ügyfélértékelések"
        ogTitle="Online weboldal és időpontfoglaló rendszer"
        ogDescription="Készítsd el saját weboldalad, ami tartalmazza az időpontfoglaló rendszert, és érd el könnyedén a vállalkozásod online jelenlétét."
        ogImage="https://wobble.hu/assets/idopontfoglalo-rendszer-szepsegiparban.png"
      />
      <Box width="100%" p={4} textAlign="center" pb={20} bg="gray.50">
        <Heading
          as="h1"
          mb={8}
          fontSize={{ base: "3xl", md: "5xl" }}
          fontWeight="bold"
          bgGradient="linear(to-r, teal.300, blue.500)"
          bgClip="text"
        >
          Online megjelenés, weboldal
        </Heading>

        <Heading
          fontSize={{ base: "md", md: "xl" }}
          mt={4}
          color="gray.700"
          maxW={800}
          mb={10}
          justifySelf="center"
        >
          Készítsd el saját weboldalad, ami tartalmazza az időpontfoglaló
          rendszert is!
        </Heading>
        <Text>Pörgesd át a demo weboldalakat ⬇️</Text>
        <ScrollingIcon />
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          width={sectionWidth}
          mx="auto"
          mb={50}
        >
          <Box
            flex="1"
            mr={{ md: 4 }}
            mb={{ base: 4, md: 0 }}
            transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <MonitorSection imageUrl={dog_web} />
          </Box>

          <Box
            flex="1"
            mt={{ base: 4, md: 6 }}
            p={4}
            borderRadius="lg"
            transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <MonitorSection imageUrl={clean_web} />
          </Box>
        </Box>

        <Box
          width={sectionWidth}
          mx="auto"
          p={6}
          bg="white"
          borderRadius="lg"
          boxShadow="lg"
          border="1px solid #e2e8f0"
          mb={8}
          maxW={500}
          // _hover={{
          //   boxShadow: "2xl",
          //   transform: "scale(1.03)",
          //   transition: "all 0.3s ease-in-out",
          // }}
          // transition="transform 0.3s ease-in-out"
        >
          <List spacing={4} textAlign="left">
            {listItems.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <ListItem
                  display="flex"
                  alignItems="center"
                  p={1}
                  borderRadius="md"
                  _hover={{
                    bg: "teal.50",
                    color: "teal.500",
                    cursor: "pointer",
                    transform: "translateX(5px)",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  <ListIcon as={CheckCircleIcon} color="teal.500" />
                  <Text
                    fontSize="lg"
                    fontWeight="medium"
                    _hover={{ color: "teal.500" }}
                    transition="color 0.2s ease"
                  >
                    {item}
                  </Text>
                </ListItem>
              </motion.div>
            ))}
          </List>
        </Box>

        <Heading
          fontSize={{ base: "md", md: "2xl" }}
          mt={4}
          color="gray.700"
          maxW={800}
          mb={10}
          justifySelf="center"
        >
          Nézd meg ugyanazt a demóoldalt mindkét sablonnal (hamarosan kész a második sablon is), és képzeld el,
          hogyan mutatna vele a saját vállalkozásod.
        </Heading>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          width={sectionWidth}
          mx="auto"
        >
          <Box
            flex="1"
            mr={{ md: 4 }}
            mb={{ base: 4, md: 0 }}
            transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <MonitorSection imageUrl={beauty_default} />
            <Text as="i">Alapértelmezett</Text>
          </Box>

          <Box
            flex="1"
            mt={{ base: 4, md: 6 }}
            p={4}
            borderRadius="lg"
            transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <MonitorSection imageUrl={beauty_classic} />
            <Text as="i" >Klasszik</Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebsiteSection;

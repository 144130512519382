import { Link } from "react-router-dom";
import {
  Box,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  CardHeader,
  Image,
  Text,
} from "@chakra-ui/react";
import { blogPosts } from "./blogData";
import logo from "../../assets/logo/logo_wobble.png";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <>
     <Helmet>
        <title>Wobble Blog</title>
        <meta name="description" content="Wobble blog cikkek AI, idopontfoglalo rendszerol, online megjelenes, online idopontfoglalo, segitseg szolgaltatasoknak temakban." />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "headline": "Blog",
              "description": "Wobble blog cikkek AI, idopontfoglalo rendszerol, online megjelenes, online idopontfoglalo, segitseg szolgaltatasoknak temakban.",
              "author": {
                "@type": "Person",
                "name": "Wobble"
              },
              "image": "ttps://wobble.hu/assets/wobble_idopontfoglalo.png"
            }
          `}
       </script>
      </Helmet>
      <Box py={10} px={4} bgGradient="linear(#5AB3C5, #E9F0F1)">
        <Heading size="2xl" mb={8} textAlign="left" as="i" color="#007188">
          <Link target="blank" to={`https://wobble.hu`}><Image src={logo} h={50}/></Link>
          blog
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} mt={8}>
          {blogPosts.map((post) => (
            <Card
              key={post.id}
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
              bg="white"
            >
              <CardHeader>
                <Image
                  src={post.thumbnail}
                  alt={post.title}
                  borderRadius="md"
                  maxH="300px"
                />
              </CardHeader>
              <CardBody>
                <Heading as="h3" size="lg" mb={4} color="#007188">
                  {post.title}
                </Heading>
                <Text noOfLines={3} fontSize="md" color="#024856" mb={4}>
                  {post.content.map((item) =>
                    item.type === "text" ? item.content : null
                  )}
                </Text>
                <Link to={`/blog/${post.slug}`}>
                  <Box
                    as="button"
                    p={3}
                    color="white"
                    fontWeight="bold"
                    borderRadius="md"
                    bgGradient="linear(to-r, #5AB3C5, #007188)"
                    _hover={{
                      bgGradient: "linear(to-r, #024856, #5AB3C5)",
                    }}
                  >
                    Elolvasom
                  </Box>
                </Link>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default BlogPage;
